import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import RichText from '~components/RichText'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'

const Page = ({ data }) => {

	const page = data?.sanityPage

	return (
		<Wrap>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<Row>
				<Title className='h2-serif'>{page?.title}</Title>
				<RichText content={page?.text}/>
			</Row>
			<DataViewer data={page} name="page" />
		</Wrap>
	)
}

const Wrap = styled(Section)`
	
`
const Row = styled.div`
	grid-column: span 16;
	margin: 140px auto var(--s);
	max-width: 1200px;
`
const Title = styled.h1`
	margin-bottom: var(--m);
`

Page.propTypes = {
	className: PropTypes.string,
	data: PropTypes.node,
}

export const query = graphql`
	  query PageQuery($slug: String) {
    	sanityPage(slug: {current: {eq: $slug}}) {
				title
				slug {
					current
				}
				text: _rawText(resolveReferences: {maxDepth: 5})
				seo{
					...seo
				}
			}
		}
`

export default Page